<template>
  <div>
    <b-row>
      <b-col>
        <h2>Invoice Summary</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="3">
        <strong>Sync Status:</strong>
      </b-col>
      <b-col>
        <span>{{ invoiceObj.ExternalSystemSyncStatus }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Created:</strong>
      </b-col>
      <b-col>
        <span>{{ invoiceObj.CreatedAt | dateFormat }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Created By:</strong>
      </b-col>
      <b-col>
        <span>
          <router-link :to="'/admin/users-and-permissions/users/edit/' + invoiceObj.CreatedByUserID">
            {{ invoiceObj.CreatedBy }}
          </router-link>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>NetSuite Invoice Number:</strong>
      </b-col>
      <b-col>
        <span>{{ invoiceObj.InvoiceDisplayNumber }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Client:</strong>
      </b-col>
      <b-col>
        <span>
          <router-link :to="'/clients/view/' + invoiceObj.ClientID">
            {{ invoiceObj.ClientName }}
          </router-link>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Branch:</strong>
      </b-col>
      <b-col>
        <span>
          <router-link :to="'/clients/' + invoiceObj.ClientID + '/branch/' + invoiceObj.BranchID + '/edit'">
            {{ invoiceObj.BranchName }}
          </router-link>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Case:</strong>
      </b-col>
      <b-col>
        <span>
          <router-link :to="'/cases/' + invoiceObj.CaseID + '/detail'">
            {{ invoiceObj.CaseFileNumber }}
          </router-link>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Imported to NetSuite:</strong>
      </b-col>
      <b-col>
        <span>{{ "Imported to NetSuite" }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <strong>Void Status:</strong>
      </b-col>
      <b-col>
        <span v-if="!invoiceObj.VoidedAt">
          <span>Active (Not Voided)</span>
          <span
            v-if="CurrentScopes.indexOf('st2.voidinvoices') !== -1"
            class="cursor-pointer btn-link"
            @click="showModal"
          >(Void Invoice)</span>
        </span>
        <span v-else>{{ invoiceObj.VoidedAt }} {{ invoiceObj.VoidedBy }}</span>
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col md="3">
        <strong>Time</strong>
      </b-col>
      <b-col>
        <strong>Details</strong>
      </b-col>
    </b-row>
    <b-row
      v-for="(item, index) in invoiceObj.InvoiceSyncLog"
      :key="index"
    >
      <b-col md="3">
        {{ item.Timestamp | dateFormat }}
      </b-col>
      <b-col>{{ item.Message }}</b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>Are you sure you want to void this invoice? This cannot be undone.</strong>
          </h2>
          <h3><strong>Important:</strong> This void will have to manually be completed in NetSuite as well, and the invoice document will need to be removed from Case Files.</h3>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="hideModal()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="voidInvoice()"
            >
              Void
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
  },
  data() {
    return {
      InvoiceID: "",
      invoiceObj: {},

      modalShow: false,

      SyncStatus: "",
      Created: "",
      CreatedBy: "",
      NetSuiteInvoiceNumber: "",
      Client: "",
      Branch: "",
      Case: "",
      ImportedToNetSuite: "",
      VoidStatus: "",
      CurrentScopes: [],
    }
  },
  created() {
    this.InvoiceID = this.$route.params.invoiceID;
    if (this.InvoiceID) {
      this.loadInvoice();
    }
  },
  mounted() {
    this.CurrentScopes = localStorage.getItem("UserScopes");
  },
  methods: {
    loadInvoice() {
      apiService
          .get("invoice/" + this.InvoiceID)
          .then(res => {
            this.invoiceObj = res.data;
          })
    },
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    },
    voidInvoice() {
      apiService
          .post("invoice/" + this.InvoiceID + "/void")
          .then(() => {
            this.modalShow = false;
            this.loadInvoice();
          })
    },
  }
}
</script>

<style scoped>
span {
  font-weight: 400;
}
</style>